export const DotsOnPathIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    viewBox='18.289 18.289 63 65'>
    <linearGradient
      id='DotsOnPathIcon'
      x1={18.289}
      x2={81.711}
      y1={50.319}
      y2={50.319}
      gradientUnits='userSpaceOnUse'>
      <stop offset={0} stopColor='#00aece' />
      <stop offset={0.486} stopColor='#8a2ff1' />
      <stop offset={1} stopColor='#fd67ff' />
    </linearGradient>
    <path
      d='M81.71 72.96a9.02 9.02 0 0 1-3.87 7.43 9.048 9.048 0 0 1-8.31 1.06 9.043 9.043 0 0 1-5.61-6.23H36.43c-3.6 0-7.06-1.43-9.61-3.98s-3.98-6-3.98-9.61c0-3.6 1.43-7.06 3.98-9.61s6-3.98 9.61-3.98h4.85a9.013 9.013 0 0 1 17.47 0h4.85c3.24 0 6.23-1.73 7.84-4.53a9.052 9.052 0 0 0 0-9.06 9.067 9.067 0 0 0-7.84-4.53H36.11a9.057 9.057 0 0 1-5.62 6.23 9.036 9.036 0 0 1-8.32-1.06 9.056 9.056 0 0 1 0-14.86 9.065 9.065 0 0 1 8.32-1.06 9.057 9.057 0 0 1 5.62 6.23H63.6c3.6 0 7.06 1.43 9.61 3.98s3.98 6 3.98 9.61c0 3.6-1.43 7.06-3.98 9.61s-6 3.98-9.61 3.98h-4.85a9.013 9.013 0 0 1-17.47 0h-4.85c-3.24 0-6.23 1.73-7.84 4.53a9.052 9.052 0 0 0 0 9.06 9.067 9.067 0 0 0 7.84 4.53h27.49c.74-2.87 2.83-5.2 5.61-6.23s5.88-.64 8.31 1.06a9.02 9.02 0 0 1 3.87 7.43z'
      fill='url(#DotsOnPathIcon)'
    />
  </svg>
)
