import type { FAQItem } from '@/web/components/FAQAccordion'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/web/components/ui/accordion'
export function LandingFAQ({
  questionsAnswers,
}: {
  questionsAnswers: FAQItem[]
}) {
  return (
    <Accordion
      type='single'
      collapsible
      className='w-full md:max-w-xl xl:max-w-3xl mx-auto'
      variant='card'>
      {questionsAnswers.map(item => (
        <AccordionItem key={item.question} value={`item-${item.question}`}>
          <AccordionTrigger>{item.question}</AccordionTrigger>
          <AccordionContent>{item.answer}</AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
