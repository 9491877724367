import { REDDIT_DESCRIPTION, REDDIT_SLOGAN } from '@/shared/constants/company'
import { REDDIT_LANDING_FAQ } from '@/shared/constants/reddit'
import { AnimateIn } from '@/web/components/AnimateIn'
import { FeatureCards, type Feature } from '@/web/components/FeatureCards'
import { AdaptingShapesIcon } from '@/web/components/icons/custom/AdaptingShapesIcon'
import { ChartsTimeIcon } from '@/web/components/icons/custom/ChartsTimeIcon'
import { DotsOnPathIcon } from '@/web/components/icons/custom/DotsOnPathIcon'
import { GearSourcesIcon } from '@/web/components/icons/custom/GearSourcesIcon'
import { RedditIcon } from '@/web/components/icons/RedditIcon'
import { HowItWorksIllustration } from '@/web/components/illustrations/HowItWorks'
import { HowItWorks1 } from '@/web/components/illustrations/HowItWorks1'
import { HowItWorks2 } from '@/web/components/illustrations/HowItWorks2'
import { HowItWorks3 } from '@/web/components/illustrations/HowItWorks3'
import { LandingFAQ } from '@/web/components/LandingFAQ'
import { LandingHeading } from '@/web/components/LandingHeading'
import { LandingSection } from '@/web/components/LandingSection'
import { PricingPlans } from '@/web/components/PricingPlans'
import { Testimonials } from '@/web/components/TestimonialMarquee'
import { Button } from '@/web/components/ui/button'
import { REDDIT_TESTIMONIALS } from '@/web/constants/landing'
import { ROUTE } from '@/web/constants/router'
import { BACKGROUND_DOTS_WITH_BOTTOM_DIVIDER_STYLE } from '@/web/constants/style'
import { IntegrationName } from '@prisma/client'
import Link from 'next/link'
import { LetterWriteIcon } from '../components/icons/custom/LetterWriteIcon'
import { PlugIcon } from '../components/icons/custom/PlugIcon'

export default function RedditPage() {
  return (
    <div className='flex flex-col gap-24'>
      <RedditHero />
      <RedditHowItWorks />
      <RedditTestimonials />
      <RedditFeatures />
      <RedditPricing />
      <RedditFaq />
      <RedditGetStarted />
    </div>
  )
}

function RedditHero() {
  return (
    <LandingSection
      id='hero'
      className='w-full px-2 pb-32 pt-24 lg:px-8 lg:pt-32 xl:px-20 xl:pb-40 2xl:px-32 2xl:pb-40 xl:pt-32 flex flex-col gap-y-16 2xl:gap-y-24'
      style={BACKGROUND_DOTS_WITH_BOTTOM_DIVIDER_STYLE}>
      <AnimateIn className='flex flex-col-reverse items-center justify-center gap-8 xl:gap-12 xl:flex-row z-10'>
        <div className='w-full h-full xl:flex-[1.618] relative'>
          <video
            src='/videos/reddit-demo.mp4'
            controls
            autoPlay
            loop
            muted
            playsInline
            preload='metadata'
            poster='/images/reddit-demo-poster.png'
            className='w-full h-full object-cover'
            controlsList='nodownload'>
            <track
              kind='captions'
              srcLang='en'
              label='English'
              src='/videos/reddit-demo.vtt'
              default
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className='flex max-w-2xl flex-col items-center justify-center gap-8 space-y-2 text-center flex-1'>
          <div className='flex flex-col gap-2'>
            <h1>
              <RedditIcon isColored className='size-20 mx-auto' />
              {REDDIT_SLOGAN}
            </h1>
            <h4 className='text-muted-foreground'>{REDDIT_DESCRIPTION}</h4>
          </div>
          <Link href={ROUTE.SIGN_IN}>
            <Button theme='premium' size='xl' className='mt-8'>
              Get Started for Free
            </Button>
          </Link>
        </div>
      </AnimateIn>
      {/* <AnimateIn index={1} className='z-10'>
          <LogoCloud
            logos={GMAIL_COMPANY_LOGOS}
            title='Trusted by thousands of pioneers at forward-thinking companies'
            width={120}
            height={40}
          />
        </AnimateIn> */}
    </LandingSection>
  )
}

function RedditHowItWorks() {
  return (
    <LandingSection
      className='flex flex-col gap-24 md:gap-24 xl:gap-48 justify-center !px-0'
      id='how-it-works'>
      <AnimateIn className='flex flex-col gap-8 text-center md:gap-0'>
        <LandingHeading
          title='How It Works'
          subtitle='Getting set up only takes a few minutes.'
        />
        <div className='hidden md:block'>
          <HowItWorksIllustration
            text2a='Select'
            text2b='Targets'
            text3a='Promote'
            text3b='Brand'
          />
        </div>
      </AnimateIn>
      <AnimateIn className='mr-auto grid w-full max-w-6xl grid-cols-1 flex-wrap place-items-center items-center justify-center gap-x-4 gap-y-8 px-8 text-center sm:gap-8 md:text-left md:[grid-template-columns:clamp(300px,10vw,600px)_1fr]'>
        <HowItWorks1 className='w-full max-w-xs' />
        <div className='flex flex-1 flex-col gap-6'>
          <h3>Connect your Reddit account</h3>
          <h6 className='text-muted-foreground w-full text-[22.5px]'>
            Link your Reddit account to Pulse in just a few clicks. By
            integrating your business details, Pulse crafts personalized replies
            that promote your brand effectively while adhering to Reddit’s
            guidelines.
          </h6>
        </div>
        {/* <Image
          src='/images/reddit-connect-button.png'
          alt='Reddit Mass Generate Replies'
          layout='responsive'
          objectFit='cover'
          width={466}
          height={54}
          className='shadow-primary/50 w-full max-w-[466px] rounded-sm shadow-md [grid-column:span_2]'
        /> */}
      </AnimateIn>
      <AnimateIn className='mx-auto grid w-full max-w-6xl grid-cols-1 flex-wrap place-items-center items-center justify-center gap-x-4 gap-y-8 px-8 text-center sm:gap-8 md:text-left md:[grid-template-columns:clamp(300px,10vw,600px)_1fr]'>
        <HowItWorks2 className='w-full max-w-xs' />
        <div className='flex flex-1 flex-col gap-6'>
          <h3>Target your ideal audience</h3>
          <h6 className='text-muted-foreground w-full text-[22.5px]'>
            Receive instant notifications when relevant discussions arise. Pulse
            generates spam-proof, engaging comments ready for you to send,
            helping you build credibility and grow your brand on Reddit.
          </h6>
        </div>
        {/* <Image
          src='/images/reddit-mass-generate-replies.png'
          alt='Reddit Mass Generate Replies'
          layout='responsive'
          objectFit='cover'
          width={943}
          height={116}
          className='shadow-primary/50 w-full max-w-[943px] rounded-sm shadow-md [grid-column:span_2]'
        /> */}
      </AnimateIn>
      <AnimateIn className='ml-auto grid w-full max-w-6xl grid-cols-1 flex-wrap place-items-center items-center justify-center gap-x-4 gap-y-8 px-8 text-center sm:gap-8 md:text-left md:[grid-template-columns:clamp(300px,10vw,600px)_1fr]'>
        <HowItWorks3 className='w-full max-w-xs' />
        <div className='flex flex-1 flex-col gap-6'>
          <h3>Engage and grow effortlessly</h3>
          <h6 className='text-muted-foreground w-full text-[22.5px]'>
            Pulse will notify you whenever a relevant post or comment is made
            with your keywords and draft a spam-proof comment for you. You can
            also draft comments to grow your karma in your target subreddits.
          </h6>
        </div>
        {/* <Image
          src='/images/reddit-reply-generated-example.png'
          alt='Reddit Mass Generate Replies'
          layout='responsive'
          objectFit='cover'
          width={559}
          height={177}
          className='shadow-primary/50 w-full max-w-[559px] rounded-sm shadow-md [grid-column:span_2]'
        /> */}
      </AnimateIn>
    </LandingSection>
  )
}

function RedditTestimonials() {
  return (
    <section className='w-full py-12 md:py-16 lg:py-24' id='testimonials'>
      <Testimonials testimonials={REDDIT_TESTIMONIALS} />
    </section>
  )
}

const REDDIT_FEATURES: Feature[] = [
  {
    icon: LetterWriteIcon,
    title: 'Effortless Comment Creation',
    description:
      'Save time with AI-drafted comments tailored to your target subreddits, aligning with community interests to maximize upvotes and engagement.',
  },
  {
    icon: PlugIcon,
    title: 'Real-Time Keyword Monitoring',
    description:
      'Stay ahead with instant alerts on keywords relevant to your business, ensuring you never miss new, relevant discussions.',
  },
  {
    icon: AdaptingShapesIcon,
    title: 'Spam-Proof Engagement',
    description:
      'Engage communities safely with replies that comply with Reddit’s guidelines, preventing your comments from being flagged as spam.',
  },
  {
    icon: DotsOnPathIcon,
    title: 'Validated and Relevant Alerts',
    description:
      'Focus on what matters most by receiving notifications only for truly relevant discussions, complete with pre-drafted responses.',
  },
  {
    icon: GearSourcesIcon,
    title: 'Comment Quality Assurance',
    description:
      'Ensure meaningful engagement with comments drafted only when they add real value, protecting your account from bans.',
  },
  {
    icon: ChartsTimeIcon,
    title: 'Smart Promotion Switch',
    description:
      'Build trust on Reddit first, then seamlessly transition to promoting your brand to convert engaged users into customers.',
  },
]

function RedditFeatures() {
  return (
    <LandingSection
      className='wavy-border-top wavy-border-bottom bg-base-300'
      id='features'>
      <LandingHeading
        title='Powerful Growth Features'
        subtitle='Harness the full potential of Reddit with Pulse’s innovative tools.'
      />
      <FeatureCards features={REDDIT_FEATURES} />
    </LandingSection>
  )
}

function RedditPricing() {
  return (
    <LandingSection id='pricing' className='space-y-8'>
      <LandingHeading
        title='Simple, Transparent Pricing'
        subtitle='Start for free. Upgrade anytime to unlock more features.'
      />
      <PricingPlans
        integrationName={IntegrationName.REDDIT}
        // type='simple'
        isStatic
      />
    </LandingSection>
  )
}

function RedditFaq() {
  return (
    <LandingSection
      className='wavy-border-top wavy-border-bottom bg-base-300 flex flex-col justify-center gap-8'
      id='faq'>
      <LandingHeading
        title='Frequently Answered Questions'
        subtitle='Asking questions is free. Not asking them could be expensive.'
      />
      <LandingFAQ questionsAnswers={REDDIT_LANDING_FAQ} />
    </LandingSection>
  )
}

function RedditGetStarted() {
  return (
    <section className='w-full py-12 md:py-24 lg:py-32'>
      <div className='grid-in-container container px-4 md:px-6'>
        <div className='flex flex-col items-center justify-center space-y-4 text-center'>
          <div className='flex flex-col gap-2 text-center items-center w-full md:max-w-3xl'>
            <h1>Ready to boost your Reddit engagement?</h1>
            <h4 className='text-muted-foreground'>
              Simply sign up for Pulse and then enable the integration for
              Reddit.
            </h4>
            <Link href={ROUTE.SIGN_IN}>
              <Button theme='premium' size='xl' className='mt-8'>
                Get Started for Free
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
