export const BACKGROUND_DOTS_STYLE = {
  backgroundImage: `url('/backgrounds/dots.svg')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  maskImage: `linear-gradient(black 0 0)`,
  // maskSize: '100% calc(100% - 100px), 1000px 100px',
  maskRepeat: 'no-repeat, repeat-x',
  maskPosition: 'top, bottom',
}
export const BACKGROUND_DOTS_WITH_BOTTOM_DIVIDER_STYLE = {
  backgroundImage: `url('/backgrounds/dots.svg')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  maskImage: `linear-gradient(black 0 0), url('/backgrounds/divider.svg')`,
  maskSize: '100% calc(100% - 100px), 1000px 100px',
  maskRepeat: 'no-repeat, repeat-x',
  maskPosition: 'top, bottom',
}
